import { useAuthStore } from '@/stores/auth.store';

export default async function authGuard(to, _from, next) {
    if (!to.meta.auth) {
        return next();
    }

    const authStore = useAuthStore();

    await authStore.waitUntilUserIsFetched();

    if (!authStore.user) {
        return next({ name: 'auth.login' });
    }

    return next();
}
