import { computed, ref, type Ref } from 'vue';

import { defineStore } from 'pinia';

import type Order from '@/models/order.model';
import type Plan from '@/models/plan.model';
import type { PlanResponse } from '@/models/plan.model';
import type Subscription from '@/models/subscription.model';
import type { Member, SubscriptionResponse } from '@/models/subscription.model';

export const useSubscriptionStore = defineStore('subscription', () => {
    const currentAccount: Ref<Subscription | undefined> = ref();
    const otherAccounts: Ref<Subscription[]> = ref([]);
    const isOwner: Ref<boolean> = ref(true);
    const backupSubscription: Ref<(Order & { plan?: Plan }) | undefined> = ref();
    const allPlans: Ref<Plan[]> = ref([]);
    const orders: Ref<Order[]> = ref([]);

    // Computed

    const plan = computed(() => {
        const planId = currentAccount.value?.planId || backupSubscription.value?.planId;

        if (!planId) {
            return;
        }

        return allPlans.value.find((plan) => plan.planId == planId);
    });

    const familyMembers = computed(() => {
        return currentAccount.value?.otherMembers || [];
    });

    const familyMembersProcessing = computed(() => {
        return orders.value.filter((order) => !order.isComplete && findPlan(order.planId)?.type == 'family_plan');
    });

    const allFamilyMembers = computed(() => {
        return [...familyMembers.value, ...familyMembersProcessing.value];
    });

    const allAccounts = computed(() => {
        return [currentAccount.value, ...otherAccounts.value];
    });

    // Functions

    function findPlan(planId: string | undefined) {
        if (!planId) {
            return;
        }

        return allPlans.value.find((plan) => plan.planId == planId);
    }

    return {
        isOwner,
        currentAccount,
        otherAccounts,
        backup: backupSubscription,
        allPlans,
        orders,

        // Computed

        plan,
        familyMembers,
        familyMembersProcessing,
        allFamilyMembers,
        allAccounts,

        value: computed(() => {
            return currentAccount.value;
        }),

        isFamily: computed(() => {
            return plan.value?.type == 'family_plan';
        }),

        canAddMoreFamilyMembers: computed(() => {
            if (plan.value?.type != 'family_plan') {
                return false;
            }

            const maxMembers = plan.value.maxMembers;

            return maxMembers > allFamilyMembers.value.length;
        }),

        nextFamilyMemberPrice: computed(() => {
            if (plan.value?.type != 'family_plan') {
                return 0;
            }

            const index =
                allFamilyMembers.value.length > plan.value.memberPrices.length - 1
                    ? plan.value.memberPrices.length - 1
                    : allFamilyMembers.value.length;

            return plan.value.memberPrices[index]?.price || 0;
        }),

        otherFamilyMembers: computed(() => {
            const accounts = otherAccounts.value.filter((account) => {
                const planId = account.planId;

                if (!account.members?.length) {
                    return;
                }

                return true;
            });

            let otherFamilyMembers: Member[] = [];

            for (const account of accounts) {
                if (!account.members) {
                    continue;
                }

                otherFamilyMembers = [...otherFamilyMembers, ...account.members];
            }

            return otherFamilyMembers;
        }),

        otherFamilyMembersProcessing: computed(() => {
            return [] as any[];
        }),

        individuals: computed(() => {
            return [currentAccount.value, ...otherAccounts.value].filter((account) => findPlan(account?.planId)?.type == 'individual_plan');
        }),

        individualsProcessing: computed(() => {
            return orders.value.filter((order) => !order.isComplete && findPlan(order.planId)?.type == 'individual_plan');
        }),

        hasIncompleteOrders: computed(() => {
            return orders.value.some((order) => !order.isComplete);
        }),

        // Functions

        $set(data: SubscriptionResponse) {
            currentAccount.value = data.currentAccount;
            otherAccounts.value = data.otherAccounts;
            isOwner.value = data.isOwner;
        },

        getAccount(phoneNumber: string) {
            if (!allAccounts.value) {
                return;
            }

            return allAccounts.value.find((account) => {
                let member = account?.currentMember;

                if (!member) {
                    member = account?.members?.find((member) => member.phoneNumber == phoneNumber);
                }

                if (!member) {
                    return;
                }

                return member.phoneNumber == phoneNumber;
            });
        },

        // TODO: Remove hardcoded special
        isEligibleForFreeDataSpecial(plan: Plan) {
            const now = new Date();
            const specialEndsAt = new Date(2024, 7, 1); // 1. august kl. 00:00

            return plan.name == 'Frítt data' && now.getTime() < specialEndsAt.getTime();
        },

        loadPlans(planResponse: PlanResponse) {
            allPlans.value = [];

            for (const data of planResponse) {
                allPlans.value = [...allPlans.value, ...data.dataPlanResponses.map((plan) => ({ ...plan, planTypeName: data.name }))];
            }
        },

        async $reset() {
            currentAccount.value = undefined;
            otherAccounts.value = [];
            backupSubscription.value = undefined;
            isOwner.value = true;
        },
    };
});
