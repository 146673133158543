const cities = [
    { postCode: '927', name: 'Akrar' },
    { postCode: '160', name: 'Argir' },
    { postCode: '726', name: 'Ánir' },
    { postCode: '727', name: 'Árnafjørður' },
    { postCode: '386', name: 'Bøur' },
    { postCode: '235', name: 'Dalur' },
    { postCode: '735', name: 'Depil' },
    { postCode: '470', name: 'Eiði' },
    { postCode: '478', name: 'Elduvík' },
    { postCode: '870', name: 'Fámjin' },
    { postCode: '825', name: 'Froðba' },
    { postCode: '530', name: 'Fuglafjørður' },
    { postCode: '477', name: 'Funningsfjørður' },
    { postCode: '475', name: 'Funningur' },
    { postCode: '387', name: 'Gásadalur' },
    { postCode: '476', name: 'Gjógv' },
    { postCode: '625', name: 'Glyvrar' },
    { postCode: '666', name: 'Gøtueiði' },
    { postCode: '511', name: 'Gøtugjógv' },
    { postCode: '440', name: 'Haldarsvík' },
    { postCode: '785', name: 'Haraldssund' },
    { postCode: '767', name: 'Hattarvík' },
    { postCode: '695', name: 'Hellur' },
    { postCode: '280', name: 'Hestur' },
    { postCode: '960', name: 'Hov' },
    { postCode: '188', name: 'Hoyvík' },
    { postCode: '420', name: 'Hósvík' },
    { postCode: '796', name: 'Húsar' },
    { postCode: '230', name: 'Húsavík' },
    { postCode: '850', name: 'Hvalba' },
    { postCode: '430', name: 'Hvalvík' },
    { postCode: '740', name: 'Hvannasund' },
    { postCode: '187', name: 'Hvítanes' },
    { postCode: '494', name: 'Innan Glyvur' },
    { postCode: '180', name: 'Kaldbak' },
    { postCode: '185', name: 'Kaldbaksbotnur' },
    { postCode: '766', name: 'Kirkja' },
    { postCode: '175', name: 'Kirkjubøur' },
    { postCode: '700', name: 'Klaksvík' },
    { postCode: '495', name: 'Kolbanargjógv' },
    { postCode: '410', name: 'Kollafjørður' },
    { postCode: '285', name: 'Koltur' },
    { postCode: '780', name: 'Kunoyar bygd' },
    { postCode: '340', name: 'Kvívík' },
    { postCode: '626', name: 'Lambareiði' },
    { postCode: '627', name: 'Lambi' },
    { postCode: '438', name: 'Langasandur' },
    { postCode: '520', name: 'Leirvík' },
    { postCode: '335', name: 'Leynar' },
    { postCode: '466', name: 'Ljósá' },
    { postCode: '926', name: 'Lopra' },
    { postCode: '370', name: 'Miðvágur' },
    { postCode: '797', name: 'Mikladalur' },
    { postCode: '', name: 'Mjørkadalur' },
    { postCode: '496', name: 'Morskranes' },
    { postCode: '737', name: 'Múli' },
    { postCode: '388', name: 'Mykines' },
    { postCode: '655', name: 'Nes, Eysturoy' },
    { postCode: '925', name: 'Nes, Vágur' },
    { postCode: '437', name: 'Nesvík' },
    { postCode: '270', name: 'Nólsoy' },
    { postCode: '730', name: 'Norðdepil' },
    { postCode: '725', name: 'Norðoyri' },
    { postCode: '178', name: 'Norðradalur' },
    { postCode: '512', name: 'Norðragøta' },
    { postCode: '460', name: 'Norðskáli' },
    { postCode: '736', name: 'Norðtoftir' },
    { postCode: '690', name: 'Oyndarfjørður' },
    { postCode: '400', name: 'Oyrarbakki' },
    { postCode: '415', name: 'Oyrareingir' },
    { postCode: '450', name: 'Oyri' },
    { postCode: '950', name: 'Porkeri' },
    { postCode: '640', name: 'Rituvík' },
    { postCode: '620', name: 'Runavík' },
    { postCode: '436', name: 'Saksun' },
    { postCode: '600', name: 'Saltangará' },
    { postCode: '656', name: 'Saltnes' },
    { postCode: '360', name: 'Sandavágur' },
    { postCode: '210', name: 'Sandur' },
    { postCode: '860', name: 'Sandvík' },
    { postCode: '497', name: 'Selatrað' },
    { postCode: '416', name: 'Signabøur' },
    { postCode: '236', name: 'Skarvanes' },
    { postCode: '485', name: 'Skálabotnur' },
    { postCode: '220', name: 'Skálavík' },
    { postCode: '480', name: 'Skáli' },
    { postCode: '780', name: 'Skarð' },
    { postCode: '665', name: 'Skipanes' },
    { postCode: '240', name: 'Skopun' },
    { postCode: '260', name: 'Skúvoy' },
    { postCode: '336', name: 'Skælingur' },
    { postCode: '286', name: 'Stóra Dímun' },
    { postCode: '490', name: 'Strendur' },
    { postCode: '435', name: 'Streymnes' },
    { postCode: '726', name: 'Strond' },
    { postCode: '330', name: 'Stykkið' },
    { postCode: '970', name: 'Sumba' },
    { postCode: '186', name: 'Sund' },
    { postCode: '465', name: 'Svínáir' },
    { postCode: '765', name: 'Svínoy' },
    { postCode: '795', name: 'Syðradalur, Kalsoy' },
    { postCode: '177', name: 'Syðradalur, Streymoy' },
    { postCode: '513', name: 'Syðrugøta' },
    { postCode: '660', name: 'Søldarfjørður' },
    { postCode: '380', name: 'Sørvágur' },
    { postCode: '445', name: 'Tjørnuvík' },
    { postCode: '650', name: 'Toftir' },
    { postCode: '100', name: 'Tórshavn' },
    { postCode: '826', name: 'Trongisvágur' },
    { postCode: '798', name: 'Trøllanes' },
    { postCode: '800', name: 'Tvøroyri' },
    { postCode: '666', name: 'Undir Gøtueiði' },
    { postCode: '385', name: 'Vatnsoyrar' },
    { postCode: '900', name: 'Vágur' },
    { postCode: '358', name: 'Válur' },
    { postCode: '176', name: 'Velbastaður' },
    { postCode: '350', name: 'Vestmanna' },
    { postCode: '430', name: 'við Áir' },
    { postCode: '750', name: 'Viðareiði' },
    { postCode: '928', name: 'Víkarbyrgi' },
    { postCode: '645', name: 'Æðuvík' },
    { postCode: '827', name: 'Øravík' },
    { postCode: '826', name: 'Øravíkarlíð' },
];

export default cities;
