import { watch } from 'vue';

import { useRoute } from 'vue-router';

const eventHandlers: { name: string; callback: (...args: any[]) => void }[] = [];

export const EventBus = {
    // Important: The event bus should be initialized in App.vue.
    init() {
        const route = useRoute();

        watch(
            () => route.name,
            () => {
                while (eventHandlers.length) {
                    eventHandlers.pop();
                }
            },
        );
    },

    listen(name: string, callback: (...args: any[]) => void) {
        eventHandlers.push({ name: name, callback });
    },

    emit(name: string, ...args: any[]) {
        const handlers = eventHandlers.filter((handler) => handler.name == name);

        for (const handler of handlers) {
            handler.callback?.(...args);
        }
    },
};
