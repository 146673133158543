import type { App as VueApp } from 'vue';

import { createPinia } from 'pinia';

import { Preferences } from '@capacitor/preferences';

import i18n from '@/i18n';

import router from '@/router';

import { checkAppStatus } from './check-app-status';

import { useAuthStore } from '@/stores/auth.store';
import { useStore } from '@/stores/main.store';
import { useSubscriptionStore } from '@/stores/subscription.store';

import { Dialog } from '@/helpers/dialog.helper';
import { slug } from '@/helpers/slug.helper';

import { initAxios } from './axios';
import { initCapacitor } from './capacitor';
import { initSentry } from './sentry';

import VueClickAway from 'vue3-click-away';

import VIcon from '@/components/VIcon.vue';

export async function init(app: VueApp) {
    // Store
    const pinia = createPinia();
    app.use(pinia);

    window.$store = useStore();
    window.$auth = useAuthStore();
    window.$subscription = useSubscriptionStore();

    const locale = await Preferences.get({ key: 'locale' });

    if (locale.value) {
        i18n.global.locale.value = locale.value as 'fo' | 'en';
    }

    // Internationalization
    app.use(i18n);

    // Router
    app.use(router);

    // Sentry (only init in production)
    if (import.meta.env.VITE_ENVIRONMENT === 'production' || import.meta.env.VITE_ENVIRONMENT === 'staging') {
        initSentry(app, router);
    }

    Dialog.register();

    // Axios
    await initAxios();

    // Capacitor
    initCapacitor();

    await checkAppStatus();

    // Directives
    app.use(VueClickAway);

    // Global Components
    app.component('VIcon', VIcon);

    // Global Properties
    app.config.globalProperties.$slug = slug;
    app.config.globalProperties.$store = useStore();
    app.config.globalProperties.$auth = useAuthStore();
    app.config.globalProperties.$subscription = useSubscriptionStore();
}
