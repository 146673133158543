import { Device } from '@capacitor/device';
import { Browser } from '@capacitor/browser';

import { useStore } from '@/stores/main.store';

import { Dialog } from '@/helpers/dialog.helper';

import StatusService from '@/services/status.service';

export async function checkAppStatus() {
    const deviceInfo = await Device.getInfo();

    const store = useStore();

    // We don't need to check the version for web platform.

    if (deviceInfo.platform == 'web') {
        return;
    }

    const currentVersion = import.meta.env.VITE_APP_VERSION;

    const [response, error] = await StatusService.post({
        currentVersion,
        deviceMeta: {
            name: deviceInfo.name,
            platform: deviceInfo.platform,
            osVersion: deviceInfo.osVersion,
            model: deviceInfo.model,
            isVirtual: deviceInfo.isVirtual,
        },
    });

    if (error) {
        console.error('[check-app-status]: An error occurred.', error);

        return;
    }

    const status = response.data;

    // A new version is available, update is REQUIRED.

    if (status.mustUpgrade) {
        Dialog.alert(
            $t('Dagfør appina'),
            $t('Tú hevur eina gamla útgávu av appini. Trýst á knøttin niðanfyri fyri at dagføra appina'),
            () => {
                openStoreLink();
            },
            {
                buttonText: $t('Dagfør appina'),
                dismissable: false,
            },
        );

        return;
    }

    // A new version is available

    if (status.shouldUpgrade) {
        Dialog.confirm(
            $t('Dagfør appina'),
            $t('Tú hevur eina gamla útgávu av appini. Trýst á knøttin niðanfyri fyri at dagføra appina'),
            () => {
                openStoreLink();
            },
            {
                buttonText: $t('Dagfør appina'),
                cancelButtonText: $t('Bíða við dagføring'),
            },
        );
    }

    // The user has unread messages

    if (status.messages.some((message) => !message.isRead)) {
        const unreadMessages = status.messages.filter((message) => {
            const currentDate = new Date();

            const fromDate = new Date(message.activeFrom);
            const toDate = new Date(message.activeTo);

            if (currentDate <= fromDate && currentDate > toDate && !message.isRead) {
                return message;
            }
        });

        store.statusMessages = unreadMessages;
    }
}

async function openStoreLink() {
    const { platform } = await Device.getInfo();

    if (platform == 'ios') {
        await Browser.open({
            url: import.meta.env.VITE_MITTNEMA_APP_STORE_LINK,
        });
    }

    if (platform == 'android') {
        await Browser.open({
            url: import.meta.env.VITE_MITTNEMA_PLAY_STORE_LINK,
        });
    }
}
