import { watch, type ComputedRef, type Ref } from 'vue';

export const waitFor = {
    change(target: Ref | ComputedRef, deep = false) {
        return new Promise((resolve) => {
            const unwatch = watch(
                target,
                (target) => {
                    resolve(target);

                    unwatch();
                },
                {
                    deep,
                },
            );
        });
    },
};
