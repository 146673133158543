<script setup lang="ts">
import { ref, watch } from 'vue';

// Props

const props = defineProps({
    name: {
        type: String,
        required: true,
    },
});

// Data

const icon = ref('');

// Watchers

watch(
    () => props.name,
    async (name) => {
        if (!name) {
            return;
        }

        try {
            const response = await import(`../assets/icons/${name.replace('.', '/')}.svg?raw`);

            icon.value = response.default;
        } catch (e) {
            console.warn(`Icon '${name}' does not exist.`);
        }
    },
    {
        immediate: true,
    },
);
</script>

<template>
    <div v-html="icon"></div>
</template>
