import cities from '@/resources/cities';
import { dateFormat, getDaysInMonth, getRange, getTimeDiff, sameDate, splitTime, timeRemaining, weekDays } from './date.helper';
import errorMessage from './error.helper';
import { priceFormat } from './price.helper';
import setWindowToScrollable from './scroll.helper';

export {
    dateFormat,
    errorMessage,
    getDaysInMonth,
    getRange,
    getTimeDiff,
    priceFormat,
    sameDate,
    setWindowToScrollable,
    splitTime,
    timeRemaining,
    weekDays,
};

import { Device } from '@capacitor/device';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Toast } from './toast.helper';

export async function downloadFile(fileData: Blob, fileName: string) {
    const deviceInfo = await Device.getInfo();

    if (deviceInfo.platform == 'web') {
        return downloadFileWeb(fileData, fileName);
    }

    downloadFileMobile(fileData, fileName);
}

async function downloadFileMobile(fileData: Blob, fileName: string) {
    const file = new File([fileData], fileName, { type: 'application/pdf' });

    // Convert blob to base64
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = async () => {
        const base64 = reader.result as string;

        try {
            const result = await Filesystem.writeFile({
                path: fileName,
                data: base64,
                directory: Directory.Documents,
                recursive: true,
            });

            Toast.add(`Kvittanin er tikin niður, og liggur í Documents mappuni.`);
        } catch (e) {
            console.error('Unable to write file', e);
        }
    };
}

function downloadFileWeb(fileData: Blob, fileName: string) {
    const url = window.URL.createObjectURL(fileData);

    const anchor = document.createElement('a');

    document.body.appendChild(anchor);

    anchor.href = url;

    anchor.download = fileName;

    anchor.click();

    anchor.remove();
}

export function arrayHasSubstring(strings: string[], value: string) {
    for (const string of strings) {
        if (value.includes(string)) {
            return true;
        }
    }

    return false;
}

export function induceCityFromPostCode(postCode: string) {
    if (!postCode) {
        return;
    }

    if (isNaN(Number(postCode))) {
        return;
    }

    const city = cities.find((city) => city.postCode === postCode);

    if (!city) {
        return;
    }

    return city.name;
}

export function inducePostCodeFromCity(cityName: string) {
    if (!cityName) {
        return;
    }

    const city = cities.find((city) => city.name === cityName);

    if (!city) {
        return;
    }

    return city.postCode;
}

export function getAge(date: Date) {
    const now = new Date();

    let age = now.getFullYear() - date.getFullYear();
    let months = now.getMonth() - date.getMonth();

    if (months < 0 || (months === 0 && now.getDate() < date.getDate())) {
        age--;
    }

    return age;
}

export function getSubscriptionData(form: any) {
    const data: any = {
        planId: form.plan.planId,
        euPackage: form.plan.euPackage,
        phoneNumberReservationId: form.reservedPhoneNumberId || null,
        eSim: form.sim.type == 'ESIM',
    };

    if (form.sim.number) {
        data.simcardNumber = form.sim.number;
    }

    if (form.phoneNumber.type == 'OWN') {
        if (form.phoneNumber.isNemaNumber) {
            data.simcardNumber = form.sim.porting;
        }

        data.porting = {
            phoneNumber: form.phoneNumber.number,
            simcardNumber: form.sim.porting,
        };
    }

    if (form.sim.type == 'PHYSICAL') {
        if (form.pickupLocation.type == 'LOCATION') {
            data.simPickupLocationId = form.pickupLocation.locationId || null;
        }

        if (form.pickupLocation.type == 'HOME') {
            const address = `${$auth.user?.address}, ${$auth.user?.postCode} ${$auth.user?.city}`;

            data.simShippingAddress = address;
        }
    }

    return data;
}
