<script setup lang="ts">
// Props

const props = defineProps({
    label: {
        type: String,
        required: true,
    },
    icon: {
        type: String,
        required: false,
    },
    loading: {
        type: Boolean,
    },
    doneLoading: {
        type: Boolean,
    },
    disabled: {
        type: Boolean,
    },
    iconClass: {
        type: [String, Object],
    },
});

// Functions

function onClick(e: Event) {
    if (props.disabled || props.loading) {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
    }
}
</script>

<template>
    <button
        class="btn"
        @click="onClick"
        :disabled="disabled"
        :class="{
            'justify-between !py-3 !pr-3': icon,
        }"
    >
        <div class="relative flex items-center leading-tight">
            <span v-html="label"></span>

            <VIcon
                v-cloak
                v-if="loading"
                name="spinner"
                class="pointer-events-none ml-3 h-4 w-4 animate-spin"
            />

            <VIcon
                v-cloak
                name="checkmark"
                class="pointer-events-none ml-3 h-4 w-4 transition duration-300"
                :class="{
                    'absolute -rotate-[30deg] opacity-0': !doneLoading,
                }"
            />
        </div>

        <div
            v-if="icon"
            class="flex h-11 w-11 items-center justify-center rounded-full bg-light-blue-300 text-dark-purple-500 transition"
            :class="iconClass"
        >
            <VIcon
                class="flex h-4 w-4 items-center justify-center"
                :name="icon"
            />
        </div>
    </button>
</template>
