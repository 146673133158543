<script setup lang="ts">
import { ref } from 'vue';

import VModal from '@/components/modals/VModal.vue';
import VButton from '@/components/VButton.vue';

// Props

defineProps({
    active: {
        type: Boolean,
        required: true,
    },
    title: {
        type: String,
    },
    text: {
        type: String,
    },
    label: {
        type: String,
    },
    type: {
        type: String,
    },
    buttonClass: {
        type: String,
    },
    buttonText: {
        type: String,
    },
    callback: {
        type: Function,
        default: () => {},
    },
    cancelCallback: {
        type: Function,
        default: () => {},
    },
});

// Emits

const emit = defineEmits(['close']);

// Data

const value = ref('');

function close() {
    emit('close');

    setTimeout(() => {
        value.value = '';
    }, 250);
}
</script>

<template>
    <VModal
        :active="active"
        @close="close"
        class="text-center"
    >
        <h4 class="mb-3">{{ title }}</h4>

        <p
            v-html="text"
            class="mb-4"
        ></p>

        <form @submit.prevent>
            <div class="text-left">
                <div class="mb-1">
                    <label for="prompt-input">{{ label }}</label>
                </div>

                <input
                    ref="input"
                    id="prompt-input"
                    :type="type"
                    :value="value"
                    @input="value = ($event.target as HTMLInputElement).value"
                    class="peer w-full border-b-2 border-[#FAFAFA] bg-[#FAFAFA] p-3 transition focus:border-purple-500"
                />
            </div>

            <div class="mt-8">
                <VButton
                    :label="buttonText || $t('Í lagi')"
                    @click="
                        callback(value);

                        close();
                    "
                    :class="buttonClass"
                />

                <VButton
                    :label="$t('Angra')"
                    @click="
                        cancelCallback();

                        close();
                    "
                    class="outlined secondary mt-2"
                />
            </div>
        </form>
    </VModal>
</template>
