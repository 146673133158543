import { useStore } from '@/stores/main.store';

type ErrorOptions = {
    active?: boolean;
    text?: string;
    button?: {
        text: string;
        callback?: () => void;
    };
    keepOnScreen?: boolean;
    wifi?: boolean;
};

export default function errorMessage(options: ErrorOptions) {
    const store = useStore();

    store.error = {
        ...options,
        active: true,
    };
}
