import axios from 'axios';

import { createHttpResponse } from '@/helpers/http.helper';

import { HubConnectionBuilder, type HubConnection } from '@microsoft/signalr';

import type Agreement from '@/models/agreement.model';

export default class AgreementService {
    public static url = `${import.meta.env.VITE_API_URL}/agreements`;

    private static hubConnection: HubConnection | undefined = undefined;

    public static async get() {
        return createHttpResponse<Agreement>(axios.get(`${this.url}`));
    }

    public static async create() {
        return createHttpResponse<{ userInputUrl: string; token: string }>(axios.post(`${this.url}`));
    }

    public static async connectToPaymentHub(orderToken: string) {
        this.hubConnection = new HubConnectionBuilder().withUrl(import.meta.env.VITE_PAYMENT_HUB_URL).build();

        await this.hubConnection.start();

        await this.hubConnection.invoke('ListenToOrder', orderToken).catch((_) => {});
    }

    public static async waitUntilPaymentIsUpdated(orderToken: string) {
        return new Promise(async (resolve, reject) => {
            if (!this.hubConnection) {
                reject('Connection hub not active.');

                return;
            }

            this.hubConnection.on('PaymentUpdated', async (message: string) => {
                if (!this.hubConnection) {
                    reject('Connection hub not active.');

                    return;
                }

                resolve(message);

                this.disconnectFromPaymentHub(orderToken);
            });
        });
    }

    public static async disconnectFromPaymentHub(orderToken: string) {
        if (!this.hubConnection) {
            return;
        }

        await this.hubConnection.invoke('StopListeningToOrder', orderToken).catch((_) => {});

        await this.hubConnection.stop();
    }
}
