export default async function subscriptionGuard(to, from, next) {
    await $auth.waitUntilUserIsFetched();

    if (to.name == 'home' && !$subscription.value && !$subscription.orders.length) {
        return next({ name: 'home.welcome' });
    }

    if (to.name == 'home.welcome' && $subscription.value?.planId) {
        return next({ name: 'home' });
    }

    return next();
}
