import { ref, watch, type Ref } from 'vue';

import { defineStore } from 'pinia';

import { Preferences } from '@capacitor/preferences';

import { Formatter } from '@/helpers/formatter.helper';

import AuthService from '@/services/auth.service';

import type User from '@/models/user.model';

export const useAuthStore = defineStore('auth', () => {
    const accessToken = ref(null as string | null);
    const refreshToken = ref(null as string | null);

    const user: Ref<User | null> = ref(null);
    const userIsFetched = ref(false);

    return {
        accessToken,
        user,
        userIsFetched,

        // Setters

        async setTokens(tokens: { accessToken: string; refreshToken: string }) {
            await Preferences.set({ key: 'nema-access-token', value: tokens.accessToken });
            await Preferences.set({ key: 'nema-refresh-token', value: tokens.refreshToken });

            accessToken.value = tokens.accessToken;
            refreshToken.value = tokens.refreshToken;
        },

        // Getters

        async getUser() {
            if (user.value) {
                return user.value;
            }

            const [response, error] = await AuthService.me();

            if (error) {
                return;
            }

            user.value = Formatter.nullToString(response.data) as User;

            return user.value;
        },

        async getAccessToken() {
            if (!accessToken.value) {
                const token = await Preferences.get({ key: 'nema-access-token' });

                accessToken.value = token.value;
            }

            return accessToken.value;
        },

        async getRefreshToken() {
            if (!refreshToken.value) {
                const token = await Preferences.get({ key: 'nema-refresh-token' });

                refreshToken.value = token.value;
            }

            return refreshToken.value;
        },

        async $reset() {
            Preferences.remove({ key: 'nema-access-token' });
            Preferences.remove({ key: 'nema-refresh-token' });

            accessToken.value = null;
            refreshToken.value = null;
            user.value = null;
        },

        async waitUntilUserIsFetched() {
            if (userIsFetched.value) {
                return;
            }

            let unwatch = () => {};

            return await new Promise((resolve) => {
                unwatch = watch(
                    userIsFetched,
                    (userIsFetched) => {
                        if (userIsFetched) {
                            unwatch();

                            resolve(null);
                        }
                    },
                    {
                        immediate: true,
                    },
                );
            });
        },
    };
});
