import { Modal } from '@/helpers/modal.helper';

import AlertModal from '@/components/modals/dialog/AlertModal.vue';
import ConfirmModal from '@/components/modals/dialog/ConfirmModal.vue';
import PromptModal from '@/components/modals/dialog/PromptModal.vue';
import InfoModal from '@/components/modals/dialog/InfoModal.vue';

interface AlertOptions {
    buttonText?: string;
    dismissable?: boolean;
}

interface ConfirmOptions {
    buttonText?: string;
    cancelCallback?: Function;
    cancelButtonText?: string;
}

interface PromptOptions {
    type?: string;
    buttonText?: string;
    buttonClass?: string;
    cancelCallback?: Function;
}

export const Dialog = {
    /**
     * Registers the dialog component definitions with the Modal helper object.
     */
    register(): void {
        Modal.register([AlertModal, ConfirmModal, PromptModal, InfoModal], true);
    },

    /**
     * Displays an alert dialog.
     *
     * @param title - The title of the dialog.
     * @param text - The message to display in the dialog.
     * @param callback - A function to call when the dialog is dismissed.
     * @param buttonText - The text to display on the dialog button.
     */
    alert(title: string, text: string, callback: Function, options: AlertOptions = {}) {
        Modal.open('AlertModal', {
            title,
            text,
            callback,
            ...options,
        });
    },

    /**
     * Displays an info dialog.
     *
     * @param title - The title of the dialog.
     * @param text - The message to display in the dialog.
     * @param buttonText - The text to display on the dialog button.
     */
    info(title?: string, text?: string, buttonText?: string) {
        Modal.open('InfoModal', {
            title,
            text,
            buttonText,
        });
    },

    /**
     * Displays a confirmation dialog.
     *
     * @param title - The title of the dialog.
     * @param text - The message to display in the dialog.
     * @param callback - A function to call when the user clicks the OK button.
     * @param buttonText - The text to display on the OK button.
     * @param cancelCallback - A function to call when the user clicks the Cancel button.
     */
    confirm(title: string, text: string, callback: Function, options: ConfirmOptions = {}) {
        Modal.open('ConfirmModal', {
            title,
            text,
            callback,
            ...options,
        });
    },

    /**
     * Displays a prompt dialog.
     *
     * @param title - The title of the dialog.
     * @param text - The message to display in the dialog.
     * @param label - The text to display for the input field.
     * @param callback - A function to call when the user clicks the OK button.
     * @param options - More prompt options.
     */
    prompt(title: string, text: string, label: string, callback: Function, options: PromptOptions = {}) {
        Modal.open('PromptModal', {
            title,
            text,
            label,
            callback,
            ...options,
        });
    },

    /**
     * Closes any open dialogs.
     */
    close() {
        Modal.close('AlertModal');
        Modal.close('ConfirmModal');
        Modal.close('PromptModal');
        Modal.close('InfoModal');
    },
};
