import { computed } from 'vue';

export default [
    {
        path: '/menu',
        component: () => import(/* webpackPrefetch: true */ '@/views/menu/ViewMenu.vue'),
        meta: {
            auth: true,
        },
        children: [
            {
                path: '',
                name: 'menu',
                component: () => import(/* webpackPrefetch: true */ '@/views/menu/ViewMenuMain.vue'),
                meta: {
                    footer: true,
                    header: {
                        hasBackButton: false,
                        title: computed(() => $t('Menu')),
                    },
                },
            },
            {
                path: 'kvittanir',
                name: 'menu.receipts',
                component: () => import(/* webpackPrefetch: true */ '@/views/menu/ViewReceipts.vue'),
                meta: {
                    footer: true,
                    header: {
                        hasBackButton: true,
                        title: computed(() => $t('Kvittanir')),
                    },
                },
            },
            {
                path: 'treytir',
                name: 'menu.terms',
                component: () => import(/* webpackPrefetch: true */ '@/views/ViewMarkdown.vue'),
                meta: {
                    footer: true,
                    header: {
                        hasBackButton: true,
                        title: computed(() => $t('Treytir')),
                    },
                    markdown: 'terms',
                },
            },
            {
                path: 'privatlivspolitikkur',
                name: 'menu.privacy',
                component: () => import(/* webpackPrefetch: true */ '@/views/ViewMarkdown.vue'),
                meta: {
                    footer: true,
                    header: {
                        hasBackButton: true,
                        title: computed(() => $t('Privatlívspolitikkur')),
                    },
                    markdown: 'privacy',
                },
            },
            {
                path: 'mal',
                name: 'menu.language',
                component: () => import(/* webpackPrefetch: true */ '@/views/menu/ViewLanguage.vue'),
                meta: {
                    footer: true,
                    header: {
                        hasBackButton: true,
                        title: computed(() => $t('Mál')),
                    },
                },
            },
        ],
    },
];
