import axios from 'axios';

import { createHttpResponse } from '@/helpers/http.helper';

import type DataTransfer from '@/models/data-transfer.model';

type CreateRequest = {
    to?: string;
    from?: string;
    dataAmount: number;
};

export default class DataTransferService {
    public static url = `${import.meta.env.VITE_API_URL}/data-transfers`;

    public static async get() {
        return createHttpResponse<DataTransfer[]>(axios.get(`${this.url}`));
    }

    public static async show(dataRequestId: string) {
        return createHttpResponse<DataTransfer>(axios.get(`${this.url}/${dataRequestId}`));
    }

    public static async create(data: CreateRequest) {
        return createHttpResponse(axios.post(`${this.url}`, data));
    }

    public static async approve(dataRequestId: string) {
        return createHttpResponse(axios.post(`${this.url}/${dataRequestId}/approve`));
    }

    public static async delete(dataRequestId: string) {
        return createHttpResponse(axios.delete(`${this.url}/${dataRequestId}`));
    }
}
