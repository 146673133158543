import type { Router } from 'vue-router';

import authGuard from './guards/auth.guard';
import guestGuard from './guards/guest.guard';
import ownerGuard from './guards/owner.guard';
import subscriptionGuard from './guards/subscription.guard';

export async function addRouterMiddleware(router: Router) {
    router.beforeEach(authGuard);
    router.beforeEach(guestGuard);
    router.beforeEach(ownerGuard);
    router.beforeEach(subscriptionGuard);
}
