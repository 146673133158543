import axios from 'axios';

import { createHttpResponse } from '@/helpers/http.helper';

import type Status from '@/models/status.model';

interface DeviceMeta {
    name?: string;
    platform: string;
    osVersion: string;
    model: string;
    isVirtual: boolean;
}

interface StatusRequest {
    currentVersion: string;
    deviceMeta: DeviceMeta;
}

export default class StatusService {
    public static url = `${import.meta.env.VITE_API_URL}/status`;

    public static async post(data: StatusRequest) {
        return createHttpResponse<Status>(axios.post(this.url, data));
    }
}
