import axios from 'axios';

import { createHttpResponse } from '@/helpers/http.helper';

import type Notification from '@/models/notification.model';

export default class NotificationService {
    public static url = `${import.meta.env.VITE_API_URL}/notifications`;

    public static async get(page: number = 0, perPage: number = 15) {
        return createHttpResponse<Paginated<Notification> & { totalUnread: number }>(
            axios.get(`${this.url}`, {
                params: {
                    page,
                    perPage,
                },
            }),
        );
    }

    public static async markAsRead(notificationId: string) {
        return createHttpResponse(axios.patch(`${this.url}/${notificationId}/mark-as-read`));
    }

    public static async markAllAsRead() {
        return createHttpResponse(axios.patch(`${this.url}/mark-all-as-read`));
    }
}
