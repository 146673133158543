<script setup lang="ts">
import VModal from '@/components/modals/VModal.vue';
import VButton from '@/components/VButton.vue';

// Props

const props = defineProps({
    active: {
        type: Boolean,
        required: true,
    },
    title: {
        type: String,
    },
    text: {
        type: String,
    },
    buttonText: {
        type: String,
    },
    callback: {
        type: Function,
        default: () => {},
    },
    dismissable: {
        type: Boolean,
        default: true,
    },
});

// Emits

const emit = defineEmits(['close']);

// Functions

function dismiss(callback: Function = () => {}) {
    callback?.();

    if (props.dismissable) {
        emit('close');
    }
}
</script>

<template>
    <VModal
        :active="active"
        :dismissable="dismissable"
        @close="dismiss"
        class="text-center"
    >
        <h4
            v-if="title"
            class="mb-3"
        >
            {{ title }}
        </h4>

        <p
            v-if="text"
            v-html="text"
        ></p>

        <div class="mt-8">
            <VButton
                :label="buttonText || $t('Í lagi')"
                @click="dismiss(callback)"
            />
        </div>
    </VModal>
</template>
