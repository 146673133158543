import { ref } from 'vue';

interface IToast {
    id: number;
    message: string;
    type: ToastType;
}

type ToastType = 'info' | 'warning' | 'error';

const registeredToasts = ref([] as IToast[]);

export const Toast = {
    registeredToasts,

    /**
     * Add a new toast message to the array and returns its ID.
     *
     * @param message - The message to display in the toast notification.
     * @param type - The type of toast notification to display (e.g. 'info', 'warning', or 'error').
     * @returns - The ID of the new toast message.
     */
    add(message: string, type: ToastType = 'info') {
        const id = Date.now();

        registeredToasts.value.push({ id, message, type });

        setTimeout(() => {
            this.remove(id);
        }, 3000);

        return id;
    },

    /**
     * Remove a toast message from the array by its ID.
     *
     * @param id - The ID of the toast message to remove.
     */
    remove(id: number) {
        registeredToasts.value = registeredToasts.value.filter((toast) => toast.id != id);
    },
};
