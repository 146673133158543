import axios from 'axios';

import { createHttpResponse } from '@/helpers/http.helper';

import type Order from '@/models/order.model';

export default class OrderService {
    public static url = `${import.meta.env.VITE_API_URL}/orders`;

    public static async get() {
        return createHttpResponse<Order[]>(axios.get(this.url));
    }
}
