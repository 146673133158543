export default async function ownerGuard(to, _from, next) {
    if (!to.meta.owner) {
        return next();
    }

    if (!$subscription.isOwner) {
        return next({ name: 'home' });
    }

    return next();
}
