import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router';

import authRoutes from '@/router/routes/auth.routes';
import homeRoutes from '@/router/routes/home.routes';
import subscriptionRoutes from '@/router/routes/subscription.routes';

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),

    scrollBehavior(_to, _from, savedPosition) {
        if (!savedPosition) {
            return { top: 0 };
        }

        return { top: savedPosition.top };
    },

    routes: [...authRoutes, ...homeRoutes, ...subscriptionRoutes] as RouteRecordRaw[],
});

import { addRouterMiddleware } from './middleware';
(async function () {
    await addRouterMiddleware(router);
})();

export default router;
