<script setup lang="ts">
import VModal from '@/components/modals/VModal.vue';
import VButton from '@/components/VButton.vue';

// Props

const props = defineProps({
    active: {
        type: Boolean,
        required: true,
    },
    title: {
        type: String,
    },
    text: {
        type: String,
    },
    buttonText: {
        type: String,
    },
    callback: {
        type: Function,
        default: () => {},
    },
    cancelCallback: {
        type: Function,
        default: () => {},
    },
    cancelButtonText: {
        type: String,
    },
});

// Emits

const emit = defineEmits(['close']);

// Functions

function closeModal() {
    props.cancelCallback();
    emit('close');
}
</script>

<template>
    <VModal
        :active="active"
        @close="closeModal"
        class="text-center"
    >
        <h4
            v-if="title"
            class="mb-3"
        >
            {{ title }}
        </h4>

        <p
            v-if="text"
            v-html="text"
        />

        <div class="mt-8">
            <VButton
                :label="buttonText || $t('Í lagi')"
                @click="
                    callback();
                    $emit('close');
                "
            />

            <VButton
                :label="cancelButtonText || $t('Angra')"
                @click="closeModal"
                class="outlined mt-2"
            />
        </div>
    </VModal>
</template>
