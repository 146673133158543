import { createApp } from 'vue';

import { SplashScreen } from '@capacitor/splash-screen';

import App from './App.vue';

import './assets/style.css';

import { init } from '@/init';
import { prefetch } from './init/prefetch';

const app = createApp(App);

(async () => {
    await init(app);

    await prefetch();

    app.mount('#app');

    // Hide splash screen when the app is ready.
    await SplashScreen.hide();
})();
