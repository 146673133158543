import axios from 'axios';

import { createHttpResponse } from '@/helpers/http.helper';

import type { SubscriptionResponse } from '@/models/subscription.model';

// Requests

type Porting = {
    simcardNumber: string;
    phoneNumber: string;
};

type CreateRequest = {
    planId: string;
    euPackage: boolean;
    phoneNumberReservationId: string | null;
    eSim: boolean;
    simcardNumber: string | null;
    simPickupLocationCode: string | null;
    simShippingAddress: string | null;
    porting: Porting | null;
};

type Member = {
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    phoneNumberReservationId: string | null;
    eSim: boolean;
    simcardNumber: string | null;
    simPickupLocationCode: string | null;
    simShippingAddress: string | null;
    porting: Porting | null;
};

type UpdateEUPackageRequest = Pick<CreateRequest, 'euPackage'>;

type UpdateSubscriptionRequest = Pick<CreateRequest, 'planId' | 'euPackage'>;

export type UpdateAddMemberRequest =
    | (Pick<CreateRequest, 'euPackage'> & {
          members: Member[];
      })
    | { members: Member[] };

// Service

export default class SubscriptionService {
    public static url = `${import.meta.env.VITE_API_URL}/subscriptions`;

    public static async get() {
        return createHttpResponse<SubscriptionResponse>(axios.get(this.url));
    }

    public static async create(request: CreateRequest) {
        return createHttpResponse<void>(axios.post(this.url, request));
    }

    public static async update(id: string, request: UpdateSubscriptionRequest) {
        return createHttpResponse<void>(axios.patch(`${this.url}/${id}`, request));
    }

    public static async toggleEuPackage(request: UpdateEUPackageRequest) {
        return createHttpResponse<void>(axios.patch(this.url, request));
    }

    public static async addMembers(request: UpdateAddMemberRequest) {
        return createHttpResponse<void>(axios.patch(this.url, request));
    }

    public static async delete() {
        return createHttpResponse<void>(axios.delete(this.url));
    }

    public static async deactivatePlan(userId: string) {
        return createHttpResponse<void>(axios.delete(`${this.url}/deactivate-plan/${userId}`));
    }

    public static async deleteNumber(userId: string) {
        return createHttpResponse<void>(axios.delete(`${this.url}/delete-number/${userId}`));
    }

    public static async transferSubscriptionToUserCustomer(data: { planId: string; euPackage: boolean }) {
        return createHttpResponse<void>(axios.patch(`${this.url}/transfer-subscription-to-user-customer`, data));
    }

    public static async transferSubscriptionToNewAccount(userId: string, data: { planId: string; euPackage: boolean }) {
        return createHttpResponse<void>(axios.patch(`${this.url}/transfer-subscription-to-new-account/${userId}`, data));
    }
}
