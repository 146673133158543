export default [
    {
        path: '/nytt-hald/:planType',
        name: 'new-subscription',
        component: () => import('@/views/ViewCreateSubscription.vue'),
        meta: {
            footer: false,
            header: {
                hasBackButton: true,
                title: $t('Stovna hald'),
            },
        },
    },
    {
        path: '/legg-hald-afturat',
        name: 'add-subscription',
        component: () => import('@/views/ViewAddSubscription.vue'),
        meta: {
            footer: false,
            header: {
                hasBackButton: true,
                title: $t('Keyp einstaklingahald'),
            },
        },
    },
    {
        path: '/stovna-familju-lim',
        name: 'add-family-member',
        component: () => import('@/views/ViewAddFamilyMember.vue'),
        meta: {
            footer: false,
            header: {
                hasBackButton: true,
                title: $t('Legg familjulim afturat'),
            },
        },
    },
    {
        // A family member transfers themself out of a family plan.
        path: '/flyt-hald',
        name: 'transfer',
        component: () => import('@/views/ViewTransfer.vue'),
        meta: {
            footer: false,
            header: {
                hasBackButton: true,
                title: $t('Mítt Nema'),
            },
        },
    },
    {
        // A family member is transferred out of a family plan by the owner.
        path: '/flyt-hald/familju-lim/:id',
        name: 'transfer-family-member',
        component: () => import('@/views/ViewTransferFamilyMember.vue'),
        meta: {
            footer: false,
            header: {
                hasBackButton: true,
                title: $t('Mítt Nema'),
            },
        },
    },
    {
        path: '/dagfor-hald/:id',
        name: 'subscription.update',
        component: () => import('@/views/ViewUpdateSubscription.vue'),
        meta: {
            footer: false,
            header: {
                hasBackButton: true,
                title: $t('Mítt Nema'),
            },
        },
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: () => {
            return {
                name: 'home',
            };
        },
    },
];
