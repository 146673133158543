<script setup lang="ts">
import { useSwipe } from '@vueuse/core';
import { ref, watch, type Ref } from 'vue';

// Props

const props = defineProps({
    active: {
        type: Boolean,
        required: true,
    },
    name: {
        type: String,
    },
    dismissable: {
        type: Boolean,
        default: true,
    },
});

// Emits

const emit = defineEmits(['close']);

// Data

const container: Ref<HTMLDivElement | null> = ref(null);

// Composables

const { isSwiping, direction } = useSwipe(container);

// Watchers

watch(isSwiping, () => {
    if (!props.dismissable) {
        return;
    }

    if (direction.value == 'DOWN') {
        emit('close');
    }
});

// Functions

function onClick(e: Event) {
    const target = e.target as HTMLElement;

    if (!props.active) {
        return;
    }

    if (!target.classList.contains('tint')) {
        return;
    }

    emit('close');
}
</script>

<template>
    <div
        v-cloak
        @click="onClick"
        class="tint fixed left-0 top-0 z-50 h-full w-full bg-light-blue-500/50 transition duration-100"
        :class="{
            'pointer-events-none opacity-0': !active,
        }"
    >
        <div
            class="absolute bottom-0 left-0 max-h-[90vh] w-full overflow-auto rounded-t-3xl bg-white p-8 transition-all md:bottom-auto md:left-1/2 md:top-1/2 md:w-[37.5rem] md:-translate-x-1/2 md:-translate-y-1/2 md:rounded-3xl md:p-12"
            :class="{
                'translate-y-1/2 md:scale-90': !active,
            }"
        >
            <div
                ref="container"
                class="absolute left-0 right-0 top-0 z-10 h-20"
            ></div>

            <slot />
        </div>
    </div>
</template>
