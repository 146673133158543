import type { AxiosError, AxiosResponse } from 'axios';

export type CreateHttpResponse<T> = [AxiosResponse<T>, null] | [null, AxiosError<T>];

/**
 * This function is a utility to handle HTTP responses from Axios.
 * It accepts a promise that resolves to an AxiosResponse object,
 * and returns a new Promise that resolves to a tuple containing the response and the error.
 * In case of a successful request, the error is null and in case of a failed request,
 * the response is null.
 *
 * This function can be used to handle Axios requests in a more structured way and
 * helps to avoid the need for repetitive try-catch blocks for each request in the application.
 *
 * @template T The type of the data that is expected in the AxiosResponse.
 *
 * @param {Promise<AxiosResponse<T>>} promise - The promise that resolves to an AxiosResponse.
 *
 * @returns {Promise<CreateHttpResponse<T>>} - Returns a promise that resolves to a tuple.
 *                                             In case of a successful request, the tuple is [response, null] and
 *                                             in case of an error, the tuple is [null, error].
 *
 * @example
 * const [response, error] = await createHttpResponse(axios.get('https://api.example.com/data'));
 */
export async function createHttpResponse<T>(promise: Promise<AxiosResponse<T>>): Promise<CreateHttpResponse<T>> {
    try {
        const response = await promise;

        return [response, null];
    } catch (error) {
        return [null, error as AxiosError<T>];
    }
}
