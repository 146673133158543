import axios from 'axios';

import i18n from '@/i18n';

import { useAuthStore } from '@/stores/auth.store';

import { arrayHasSubstring } from '@/helpers';

import AuthService from '@/services/auth.service';

export async function initAxios() {
    addAuthInterceptor();
    addLanguageHeaderInterceptor();
    addRefreshTokenInterceptor();
}

function addAuthInterceptor() {
    axios.interceptors.request.use(async (config) => {
        if (!config.headers) {
            return config;
        }

        const authStore = useAuthStore();

        const accessToken = await authStore.getAccessToken();

        config.headers.Authorization = `Bearer ${accessToken}`;

        return config;
    });
}

function addLanguageHeaderInterceptor() {
    axios.interceptors.request.use(async (config) => {
        if (!config.headers) {
            return config;
        }

        config.headers['Accept-Language'] = i18n.global.locale.value;

        return config;
    });
}

// Queue of promises (requests) waiting for a new token.
const failedQueue: Array<{ resolve: Function; reject: Function }> = [];

// Function to process the queue of waiting promises (requests)
const processQueue = (error: Error | null) => {
    failedQueue.forEach((promise) => {
        if (error) {
            promise.reject(error);

            return;
        }

        promise.resolve(null);
    });

    failedQueue.length = 0;
};

/**
 * If the response has status 401, attempt to refresh the access token.
 */
function addRefreshTokenInterceptor() {
    axios.interceptors.response.use(
        function (response) {
            return response;
        },
        async function (error) {
            const request = error.request;
            const response = error.response;

            // If the user is not logged in, we don't want to refresh the token.
            if (!$auth.user) {
                return Promise.reject(error);
            }

            // We don't want to check the refresh-token response.
            if (
                arrayHasSubstring(
                    [
                        'auth/refresh-token',
                        'auth/email-login',
                        'auth/request-pin',
                        'auth/enter-pin',
                        'auth/register',
                        'auth/reset-password',
                        'auth/forgot-password',
                    ],
                    request.responseURL,
                )
            ) {
                return Promise.reject(error);
            }

            // If the response is 401, we need to refresh the access token.
            if (response.status === 401) {
                if ($store.isRefreshingTokens) {
                    return new Promise((resolve, reject) => {
                        failedQueue.push({ resolve, reject });
                    })
                        .then(() => {
                            return axios(error.config); // Missing return statement fixed
                        })
                        .catch((error) => {
                            return Promise.reject(error); // Explicitly return the rejected Promise
                        });
                }

                $store.isRefreshingTokens = true;

                const tokens = await refreshToken();

                if (!tokens) {
                    processQueue(new Error('Could not refresh token'));

                    $store.isRefreshingTokens = false;

                    await AuthService.logout();

                    return Promise.reject(error);
                }

                await $auth.setTokens(tokens);

                processQueue(null);

                $store.isRefreshingTokens = false;

                return axios(error.config);
            }

            return Promise.reject(error);
        },
    );
}

/**
 * Refresh the Access Token if a refresh token exists.
 * The function is throttled, as we don't want to keep sending requests to auth/refresh-token.
 */
let lastCalled = 0;

async function refreshToken(): Promise<{ accessToken: string; refreshToken: string } | null> {
    // Throttle
    if (lastCalled > Date.now()) {
        return null;
    }

    lastCalled = Date.now() + 1000;

    const authStore = useAuthStore();

    // Get the refresh token from Auth Store.
    const refreshToken = await authStore.getRefreshToken();

    if (!refreshToken) {
        return null;
    }

    // Refresh the tokens.
    const [response, error] = await AuthService.refreshToken(refreshToken);

    if (error) {
        return null;
    }

    return response.data;
}
