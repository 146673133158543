import { computed } from 'vue';
import menuRoutes from './menu.routes';

export default [
    {
        path: '/',
        component: () => import(/* webpackPrefetch: true */ '@/views/ViewMain.vue'),
        meta: {
            auth: true,
        },
        children: [
            {
                path: '/',
                name: 'home',
                component: () => import(/* webpackPrefetch: true */ '@/views/home/ViewHome.vue'),
                meta: {
                    footer: true,
                    header: {
                        hasBackButton: false,
                        title: computed(() => $t('Mítt Nema')),
                    },
                },
            },

            {
                path: '/valkomin',
                name: 'home.welcome',
                component: () => import(/* webpackPrefetch: true */ '@/views/ViewWelcome.vue'),
                meta: {
                    footer: true,
                    header: {
                        hideNotification: true,
                        title: $t('Vælkomin til Nema'),
                    },
                },
            },

            {
                path: 'min-brukari',
                name: 'profile',
                component: () => import(/* webpackPrefetch: true */ '@/views/home/ViewProfile.vue'),
                meta: {
                    footer: true,
                    header: {
                        hasBackButton: false,
                        title: computed(() => $t('Mín brúkari')),
                    },
                },
            },

            {
                path: 'min-brukari/gjaldsavtala',
                name: 'profile.agreement',
                component: () => import(/* webpackPrefetch: true */ '@/views/home/ViewProfilePayment.vue'),
                meta: {
                    footer: false,
                    header: {
                        hasBackButton: false,
                        hideNotification: true,
                    },
                },
            },

            {
                path: 'frabodanir',
                name: 'notifications',
                component: () => import(/* webpackPrefetch: true */ '@/views/home/ViewNotifications.vue'),
                meta: {
                    footer: true,
                    header: {
                        hasBackButton: true,
                        title: computed(() => $t('Fráboðanir')),
                    },
                },
            },

            {
                path: 'familju-limur/:id',
                name: 'home.familymember',
                component: () => import(/* webpackPrefetch: true */ '@/views/home/ViewFamilyMember.vue'),
                meta: {
                    footer: true,
                    owner: true,
                    header: {
                        hasBackButton: true,
                        title: computed(() => $t('Um familjulim')),
                    },
                },
            },

            {
                path: 'familju-limur/vidgerd/:phone',
                name: 'home.familymember.phone',
                component: () => import(/* webpackPrefetch: true */ '@/views/home/ViewFamilyMember.vue'),
                meta: {
                    footer: true,
                    owner: true,
                    header: {
                        hasBackButton: true,
                        title: computed(() => $t('Um familjulim')),
                    },
                },
            },

            {
                path: 'umsit-hald/:id',
                name: 'home.manage',
                component: () => import(/* webpackPrefetch: true */ '@/views/home/ViewManageSubscription.vue'),
                meta: {
                    footer: true,
                    owner: true,
                    header: {
                        hasBackButton: true,
                        title: computed(() => $t('Umsit hald')),
                    },
                },
            },

            {
                path: '/:pathMatch(.*)*',
                redirect: () => {
                    return { name: 'home' };
                },
            },

            ...menuRoutes,
        ],
    },
];
