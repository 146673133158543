import axios from 'axios';

import { createHttpResponse } from '@/helpers/http.helper';

import type Action from '@/models/action.model';

export default class ActionService {
    public static url = `${import.meta.env.VITE_API_URL}/actions`;

    public static async get() {
        return createHttpResponse<Action[]>(axios.get(`${this.url}`));
    }
}
