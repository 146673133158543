import axios from 'axios';

import { createHttpResponse } from '@/helpers/http.helper';

import type Package from '@/models/package.model';

type PurchaseRequest = {
    productId: string;
    toPhoneNumber: string | null;
};

export default class PackageService {
    public static url = `${import.meta.env.VITE_API_URL}/packages`;

    public static async get() {
        return createHttpResponse<Package[]>(axios.get(`${this.url}`));
    }

    public static async show(id: string) {
        return createHttpResponse<Package[]>(axios.get(`${this.url}/${id}`));
    }

    public static async purchase(request: PurchaseRequest) {
        return createHttpResponse(axios.post(`${this.url}`, request));
    }
}
