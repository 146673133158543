import { App as CapacitorApp } from '@capacitor/app';
import { Network } from '@capacitor/network';

import router from '@/router';

import { useStore } from '@/stores/main.store';

import { errorMessage } from '@/helpers';
import { Modal } from '@/helpers/modal.helper';

import { checkAppStatus } from '@/init/check-app-status';

export function initCapacitor() {
    /**
     * Control for Android physical back button
     * Ignore Android back button
     */
    CapacitorApp.addListener('backButton', () => {
        const modal = Modal.activeModal.value;

        if (modal && modal.data.dismissable === false) {
            return false;
        }

        // Modal is active & dismissable, close the modal.
        if (modal) {
            Modal.close(modal.name);
        }

        // No modal is active, go back.
        if (!modal) {
            router.go(-1);
        }

        return false;
    });

    CapacitorApp.addListener('appStateChange', async ({ isActive }) => {
        if (!isActive) {
            return;
        }

        await checkAppStatus();
    });

    /**
     * Go to 'Network Error View' when Network Connection is Lost.
     */
    Network.addListener('networkStatusChange', (status) => {
        const { connected } = status;

        if (!connected) {
            errorMessage({ text: 'Áh nei, tú hevur onki samband við internetið.', keepOnScreen: true, wifi: true });

            return;
        }

        const store = useStore();

        store.error.active = false;

        setTimeout(() => {
            store.error.wifi = false;
            store.error.keepOnScreen = false;
        }, 200);
    });
}
