<script setup lang="ts">
import { onMounted } from 'vue';
import { RouterView } from 'vue-router';

import { EventBus } from '@/helpers/event-bus.helper';
import { Modal } from '@/helpers/modal.helper';

import ThePolling from '@/components/layout/ThePolling.vue';

EventBus.init();

// Lifecycle hooks

onMounted(() => {
    window.addEventListener('keydown', (e) => {
        if (e.key == 'Escape') {
            Modal.close();
        }
    });
});
</script>

<template>
    <ThePolling />

    <RouterView v-slot="{ Component }">
        <component :is="Component" />
    </RouterView>
</template>
