<script setup lang="ts">
import { computed } from 'vue';

import { useStore } from '@/stores/main.store';

import { EventBus } from '@/helpers/event-bus.helper';
import { Formatter } from '@/helpers/formatter.helper';
import { Modal } from '@/helpers/modal.helper';
import { Toast } from '@/helpers/toast.helper';

import DataTransferService from '@/services/data-transfer.service';

import VButtonCube from '@/components/VButtonCube.vue';
import VNotification from '@/components/VNotification.vue';
import VModal from '@/components/modals/VModal.vue';

// Composables

const store = useStore();

// Props

const props = defineProps({
    active: {
        type: Boolean,
        required: true,
    },
    recipientNumber: {
        type: String,
    },
    id: {
        type: String,
    },
    amount: {
        type: Number,
    },
});

// Emits

const emit = defineEmits(['close']);

// Computed

const data = computed(() => {
    const amount = props.amount || 0;

    const givenCurrent = $subscription.value?.currentMember?.givenData.current || 0;
    const givenMax = $subscription.value?.currentMember?.givenData.max || 0;

    const canGive = givenMax - givenCurrent;

    if (canGive < amount) {
        return {
            isData: false,
            message:
                canGive >= 1024
                    ? $t('Tú kanst bert geva {amount} GB afturat hendan mánaðin.', { amount: Formatter.data(canGive) })
                    : $t('Tú kanst ikki geva meira data hendan mánaðin.'),
        };
    }

    const domestic = $subscription.value?.currentMember?.dataRemaining.domestic || 0;

    if (domestic < amount) {
        return {
            isData: false,
            message: $t('Tú hevur ikki nokk data.'),
        };
    }

    return {
        isData: true,
        message: '',
    };
});

const isLastDataRequest = computed(() => store.dataTransfers.length === 1);

// Functions

async function accept() {
    if (!data.value.isData) {
        return;
    }

    if (!props.id) {
        Toast.add($t('Ein feilur hendi.'), 'error');
        emit('close');
        return;
    }

    const [_, error] = await DataTransferService.approve(props.id);

    if (error) {
        Toast.add($t('Ein feilur hendi.'), 'error');
    } else if (isLastDataRequest.value) {
        Toast.add(
            $t('Tú hevur góðtikið umbønina um <strong>{amount} GB</strong> frá <strong>{number}</strong>', {
                amount: Formatter.data(props.amount),
                number: Formatter.phone(props.recipientNumber || ''),
            }),
        );
    }

    EventBus.emit('refresh-subscription');

    emit('close');

    checkForMoreRequests();
}

async function reject() {
    if (!props.id) {
        Toast.add($t('Ein feilur hendi.'), 'error');

        emit('close');

        return;
    }

    const [_, error] = await DataTransferService.delete(props.id);

    if (error) {
        Toast.add($t('Ein feilur hendi.'), 'error');
    } else if (isLastDataRequest.value) {
        Toast.add(
            $t('<strong>{number}</strong> fær ikki data frá tær hesuferð.', {
                number: Formatter.phone(props.recipientNumber || ''),
            }),
        );
    }

    emit('close');

    checkForMoreRequests();
}

function checkForMoreRequests() {
    setTimeout(() => {
        store.dataTransfers.shift();

        if (store.dataTransfers.length > 0) {
            const dataTransfer = store.dataTransfers[0];

            Modal.open('DataRequestReceivedModal', {
                recipientNumber: dataTransfer.requester.phoneNumber,
                id: dataTransfer.id,
                amount: dataTransfer.dataAmount,
            });
        }
    }, 300);
}
</script>

<template>
    <VModal :active="active">
        <div class="relative mb-2 flex items-center">
            <h3 v-html="$t('Umbøn um data')"></h3>
        </div>

        <p
            v-html="$t('Tú hevur fingið umbøn at geva data. Niðanfyri sært tú frá hvørjum og hvussu nógv biðið er um.')"
            class="mb-8 max-w-[80%]"
        ></p>

        <div class="mb-3 flex items-center justify-between">
            <p
                class="text-sm"
                v-html="$t('Umbiðari')"
            ></p>

            <div class="text-lg font-bold">{{ Formatter.phone(recipientNumber || '') }}</div>
        </div>

        <div class="mb-8 flex items-center justify-between">
            <p
                class="text-sm"
                v-html="$t('Mongd av data')"
            ></p>

            <div class="text-lg font-bold">{{ Formatter.data(amount) }} GB</div>
        </div>

        <!-- Insufficient Funds -->

        <VNotification
            v-if="!data.isData"
            v-html="data.message"
            class="my-5 !rounded-lg"
        />

        <div class="mt-8 grid h-40 grid-cols-2 gap-4">
            <VButtonCube
                :label="$t('Avlýs umbøn')"
                href="avlys"
                icon="error-close"
                @click="reject"
            />

            <VButtonCube
                :label="$t('Góðkenn umbøn')"
                href="godkenn"
                icon="checkmark"
                :disabled="!data.isData"
                @click="accept"
            />
        </div>
    </VModal>
</template>
